import { ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetSiteId } from "../ApiHelper";

export class CheckInClient {

    static async CheckInVisitor(accessToken: string, hostId: string, carParkId: string, notices: string[], carRego: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/visitor-accounts/current/check-ins', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            body: JSON.stringify({
                siteId: await GetSiteId(accessToken),
                hostId: hostId,
                carParkId: carParkId,
                noticesAcknowledgedIds: notices,
                carRegistration: carRego,
                isInLobby: true
            })
        }));

        return response;
    }

    static async SubmitPrintRequest(accessToken: string, id: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/check-ins/'+id+"/labels", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }));

        return response;
    }
}