import { ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetSiteId } from "../ApiHelper";

export class WiFiClient {

    static async SendDetails(accessToken: string, mobile: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/sms/send-wifi-details', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            body: JSON.stringify({
                mobileNumber: mobile,
                siteId: await GetSiteId(accessToken),
                deliveryMethod: "email"
            })
        }));

        return response;
    }

}