import React, { Component } from 'react';
import { CarParkClient } from '../infrastructure/api/CarParkClient';
import { MobileNumberClient } from '../infrastructure/api/MobileNumberClient';
import { UserClient } from '../infrastructure/api/UserClient';
import { ApiHelper, ClearSiteId, GetAPIDomain, GetSiteId } from '../infrastructure/ApiHelper';
import { NavBar } from './NavBar';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { VisitorAccountClient } from '../infrastructure/api/VisitorAccountClient';
import { StaffClient } from '../infrastructure/api/StaffClient';
import { NoticesClient } from '../infrastructure/api/NoticesClient';
import ReactMarkdown from 'react-markdown'
import { CheckInClient } from '../infrastructure/api/CheckInClient';
import { WiFiClient } from '../infrastructure/api/WiFiClient';
import { SiteClient } from '../infrastructure/api/SiteClient';

export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: 'mobile', carRego:"", loading: false, errors: {}, hostName: "Search for a host", carparkName: "Search for a car park", showSuccess: false
        };

        this.onDetailsUserTypeChanged = this.onDetailsUserTypeChanged.bind(this);
    }

    //STAGES
    // 1. mobile
    // 2. validation
    // 3. details
    // 4. host
    // 5. H&S
    // 6. Confirmation

    async componentDidMount() {

        if (ApiHelper.GetCurrentCarParkId()) {
            this.setState({
                carParkId: ApiHelper.GetCurrentCarParkId()
            });
            var carParkDetails = await CarParkClient.GetCarPark(ApiHelper.GetCurrentCarParkId());
            this.setState({
                carParkName: carParkDetails.data.data.parkingBank + " - " + carParkDetails.data.data.name
            });
        }
    }

    onDetailsUserTypeChanged(e) {

        var user = this.state.user;
        user.visitorAccount.type = e.currentTarget.value;
        this.setState({
            user: user
        });


    }

    async onChangeFile(event) {

        this.setState({ imageLoading: true });

        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        this.setState({ file });

        let formData = new FormData();
        formData.append("image", file);

        const response = await fetch((await GetAPIDomain()) + "/api/users/current/profile-image", {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + this.state.accessToken
            },
            body: formData
        });

        var currentUser = (await UserClient.GetCurrentUser(this.state.accessToken)).data.data;

        if (!currentUser.visitorAccount)
            currentUser.visitorAccount = {
                mobile: this.state.mobile,
                type: 'visitor'
            };

        this.setState({ user: currentUser, imageLoading: false });

    }

    render() {

        if (this.state.stage == "mobile") {

            return (<div className="flex flex-col h-screen">
                <NavBar title="Your mobile" next={async () => {

                    this.setState({ loading: true, errors: {} });
                    if (!document.getElementById("mobile").value) {
                        this.setState({ loading: false });
                        this.setState({ errors: { phone: "Please provide your mobile number" } });
                    } else {
                        var response = await MobileNumberClient.BeginValidation(document.getElementById("mobile").value);
                        this.setState({ loading: false });
                        if (response.successful) {
                            var mobile = document.getElementById("mobile").value;
                            document.getElementById("mobile").value = "";
                            this.setState({ mobile: mobile, stage: 'validation' });
                        } else {
                            if (response.data.error != null && response.data.error.validationErrors != null && response.data.error.validationErrors.length > 0) {
                                this.setState({ errors: { phone: response.data.error.validationErrors[0].message } });
                            } else {
                                this.setState({ errors: { phone: "Sorry, we can't complete that request right now. Please ensure data entered is correct and try again." } });
                            }
                        }
                    }

                }} loading={this.state.loading} />
                <div className="container mx-auto flex-grow">
                    <div id="mobileform" className="max-w-4xl w-full mx-auto px-8 sm:px-0">

                        <p className="font-light mt-12 p-2 bg-white/50 rounded-sm hidden">Please enter you phone number to get started</p>
                        {this.state.carParkName && <p className="font-light mt-12 p-2 bg-white/50 rounded-sm">Checking in at {this.state.carParkName}</p>}

                        <input name="mobile" id="mobile" type="phone" className="form-input px-4 py-3 mt-4 rounded-full w-full" placeholder="Enter your mobile number" />
                        {this.state.errors.phone && <p className="text-red-700 font-light mt-4 p-2 bg-slate-50 rounded-sm">{this.state.errors.phone}</p>}


                    </div>
                </div>
                <div className="b-bg m-8">
                    <div className="mx-auto flex justify-center">
                        <div className="b-bg">
                            <img src="/img/logo-thumb.png" style={{ height: "100px" }} />
                        </div>
                        <span className="text-white" style={{fontSize: "38px", position: "relative", top: "20px", left:"-15px" }}>RRIVALS</span>
                    </div>
                </div>
            </div>);

        }
        else if (this.state.stage == "validation") {


            return (<div>
                <NavBar title="Validate mobile" next={async () => {

                    this.setState({ loading: true, errors: {} });
                    if (!document.getElementById("validation").value) {
                        this.setState({ loading: false });
                        this.setState({ errors: { validation: "Please provide the validation code" } });
                    } else {
                        var response = await MobileNumberClient.CompleteValidation(this.state.mobile, document.getElementById("validation").value);

                        if (response.successful) {
                            var accessCode = response.data.mobileAccessCode;

                            //CONVERT TO ACCESS TOKEN

                            var apiDomain = await GetAPIDomain();

                            this.setState({ loading: true, apiDomain: apiDomain });

                            var accessTokenResponse = await fetch(apiDomain + '/connect/token', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: new URLSearchParams({
                                    'client_id': '4a85b1e8-2956-461d-a78c-4c7c121f5f53',
                                    'grant_type': 'mobile-number',
                                    'mobile_number': this.state.mobile,
                                    'access_code': accessCode,
                                    'scope': 'offline_access'
                                })
                            });

                            var response = await accessTokenResponse.json();
                            var accessToken = response.access_token;


                            var currentUser = (await UserClient.GetCurrentUser(accessToken)).data.data;

                            if (!currentUser.visitorAccount)
                                currentUser.visitorAccount = {
                                    mobile: this.state.mobile,
                                    type: 'visitor'
                                };

                            if (this.state.carParkId) {
                                var carPark = await CarParkClient.GetCarPark(this.state.carParkId);
                                if (carPark.successful) {
                                    this.setState({
                                        carparkName: carPark.data.data.parkingBank + " - " + carPark.data.data.name
                                    });
                                }
                            }

                            this.setState({ loading: false, accessToken: accessToken, user: currentUser, stage: 'details' });


                        } else {

                            this.setState({ loading: false });
                            if (response.data.error != null && response.data.error.validationErrors != null && response.data.error.validationErrors.length > 0) {
                                this.setState({ errors: { validation: response.data.error.validationErrors[0].message } });
                            } else {
                                this.setState({ errors: { validation: "Sorry, we can't complete that request right now. Please ensure data entered is correct and try again." } });
                            }
                        }
                    }

                }} loading={this.state.loading} />
                <div className="container mx-auto">
                    <div id="validationform" className="max-w-4xl w-full mx-auto px-8 sm:px-0" onsubmit="return false;">

                        <p className="font-light mt-12 p-2 bg-white/50 rounded-sm">Please enter the validation code we sent you</p>

                        <input autoComplete="one-time-code" name="validation" id="validation" type="text" className="form-input px-4 py-3 mt-4 rounded-full w-full" placeholder="Enter validation code" />
                        {this.state.errors.validation && <p className="text-red-700 font-light mt-4 p-2 bg-slate-50 rounded-sm">{this.state.errors.validation}</p>}
                    </div>
                </div>
            </div>);

        }
        else if (this.state.stage == "details") {


            return (<div>
                <NavBar title="Your details" next={async () => {

                    if (this.state.user.visitorAccount.name == null || this.state.user.visitorAccount.name.length == 0) {
                        this.setState({
                            errors: {
                                validation: "Please enter you name"
                            }
                        });
                    } else if (this.state.user.visitorAccount.email == null || this.state.user.visitorAccount.email.length == 0) {
                        this.setState({
                            errors: {
                                validation: "Please enter you email"
                            }
                        });
                    } else {
                        this.setState({
                            errors: {},
                            loading: true
                        });

                        await VisitorAccountClient.UpsertVisitorAccount(this.state.accessToken,
                            this.state.user.visitorAccount.email,
                            this.state.user.visitorAccount.mobile,
                            this.state.user.visitorAccount.name,
                            this.state.user.visitorAccount.surname,
                            this.state.user.visitorAccount.company,
                            this.state.user.visitorAccount.type);

                        var favourites = await StaffClient.GetFavourites(this.state.accessToken);

                        var currentUser = (await UserClient.GetCurrentUser(this.state.accessToken)).data.data;
                        this.setState({ loading: false, stage: 'host', user: currentUser, favourites: favourites.data.data });
                        //this.setState({ loading: false, stage: 'host', user: currentUser, favourites: [] });

                    }

                }} loading={this.state.loading} />



                <div className="container mx-auto px-8 sm:px-0 pt-12">
                    {this.state.errors.validation && <p className="text-red-700 font-light p-2 bg-slate-50 rounded-sm max-w-4xl w-full mx-auto mb-4">{this.state.errors.validation}</p>}

                    <div className="relative mb-4">
                        <img className="w-20 h-20 rounded-full mx-auto cursor-pointer" src={this.state.user.accountPhoto == null ? "/img/missing-profile.jpg" : this.state.apiDomain + "/image/profile/" + this.state.user.accountPhoto.id} alt=""
                            onClick={() => { this.upload.click() }} />
                        <div className="relative w-7 h-7 bg-white border-2 border-white dark:border-gray-800 rounded-full shadow-xl mx-auto" style={{ top: -30, left: 30 }}>

                            {this.state.imageLoading && <svg aria-hidden="true" className="w-4 h-4 mr-2 mt-1 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 align-middle" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>}

                            {!this.state.imageLoading && <FontAwesomeIcon className="absolute left-1 top-1" icon={faEdit} />}
                            <input id="myInput"
                                type="file"
                                ref={(ref) => this.upload = ref}
                                style={{ display: 'none' }}
                                onChange={this.onChangeFile.bind(this)}
                            />
                        </div>
                    </div>


                    <form id="detailsform" className="max-w-4xl w-full mx-auto bg-white rounded-lg">
                        <div className="grid grid-cols-1 gap-4 px-4 pb-4 mx-0 sm:mx-4 sm:grid-cols-2">
                            <input name="mobile" id="mobile" type="text" className="form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2" placeholder="Mobile" value={this.state.user.visitorAccount.mobile} disabled={true} />
                            <input name="email" id="email" type="text" className="form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2" placeholder="Email" value={this.state.user.visitorAccount.email} onChange={(value) => {
                                var user = this.state.user;
                                this.state.user.visitorAccount.email = value.target.value;
                                this.setState({
                                    user: user
                                });
                            }} />
                            <input name="name" id="name" type="text" className="form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2" placeholder="First name" value={this.state.user.visitorAccount.name} onChange={(value) => {
                                var user = this.state.user;
                                this.state.user.visitorAccount.name = value.target.value;
                                this.setState({
                                    user: user
                                });
                            }} />
                            <input name="surname" id="surname" type="text" className="form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2" placeholder="Surname" value={this.state.user.visitorAccount.surname} onChange={(value) => {
                                var user = this.state.user;
                                this.state.user.visitorAccount.surname = value.target.value;
                                this.setState({
                                    user: user
                                });
                            }} />
                            <input name="company" id="company" type="text" className="form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2" placeholder="Company" value={this.state.user.visitorAccount.company} onChange={(value) => {
                                var user = this.state.user;
                                this.state.user.visitorAccount.company = value.target.value;
                                this.setState({
                                    user: user
                                });
                            }} />

                            <AsyncSelect name="park" id="park" isDisabled="true" value={{ label: this.state.carparkName, value: this.state.carParkId }} placeholder="Choose car park" loadOptions={async (input) => {
                                var options = await CarParkClient.GetCarParks(this.state.accessToken, input);
                                return options.data.data.map((t) => {
                                    return {
                                        label: t.parkingBank + " - " + t.name,
                                        value: t.id
                                    };
                                });
                            }} onChange={(option) => {
                                this.setState({
                                    carparkName: option.label,
                                    carParkId: option.value
                                });
                            }}
                                classNames={{
                                    control: () => 'form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2',
                                }} />


                            <input name="carRegistration" id="carRegistration" type="text" className="form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2" placeholder="Number plate" value={this.state.carRego} onChange={(value) => {
                                this.setState({
                                    carRego: value.target.value
                                });
                            }} />

                        </div>

                        <h5 className="mx-4 sm:mx-8 mt-4 text-slate-400">Visitor Type</h5>
                        <div className="grid grid-cols-2 gap-4 px-4 pb-4 sm:grid-cols-4 mx-0 sm:mx-4 mt-2 mb-8">
                            <div className="form-check form-check-inline pr-4">
                                <input checked={this.state.user.visitorAccount.type === "visitor"} value="visitor" onChange={this.onDetailsUserTypeChanged} className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="visitorType" id="visitor" />
                                <label className="form-check-label inline-block text-gray-800" htmlFor="visitor">Visitor</label>
                            </div>
                            <div className="form-check form-check-inline pr-4">
                                <input checked={this.state.user.visitorAccount.type === "contractor"} value="contractor" onChange={this.onDetailsUserTypeChanged} className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="visitorType" id="contractor" />
                                <label className="form-check-label inline-block text-gray-800" htmlFor="contractor">Contractor</label>
                            </div>
                            <div className="form-check form-check-inline pr-4">
                                <input checked={this.state.user.visitorAccount.type === "staff"} value="staff" onChange={this.onDetailsUserTypeChanged} className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="visitorType" id="staff" />
                                <label className="form-check-label inline-block text-gray-800" htmlFor="staff">Staff</label>
                            </div>
                            <div className="form-check form-check-inline pr-4">
                                <input checked={this.state.user.visitorAccount.type === "junior"} value="junior" onChange={this.onDetailsUserTypeChanged} className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="visitorType" id="junior" />
                                <label className="form-check-label inline-block text-gray-800" htmlFor="junior">Junior</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>);

        }
        else if (this.state.stage == "host") {

            return (<div>
                <NavBar title="Who are you seeing?" next={async () => {

                    if (this.state.hostId == null || this.state.hostId.length == 0) {
                        this.setState({
                            errors: {
                                validation: "Please select a host"
                            }
                        });
                    } else {

                        this.setState({
                            loading: true
                        });

                        //GET NOTICES
                        var notices = await NoticesClient.GetNotices(this.state.accessToken, await GetSiteId(this.state.accessToken));

                        this.setState({
                            stage: "notices",
                            notices: notices.data.data,
                            loading: false
                        });
                    }

                }} loading={this.state.loading} back={async () => {
                    this.setState({
                        stage: "details"
                    });
                }} />
                <div className="container mx-auto">
                    <form id="hostform" className="max-w-4xl w-full mx-auto px-8 sm:px-0 mt-4">

                        {this.state.errors.validation && <p className="text-red-700 font-light p-2 bg-slate-50 rounded-sm max-w-4xl w-full mx-auto mb-4">{this.state.errors.validation}</p>}

                        <AsyncSelect name="host" id="host" value={{ label: this.state.hostName, value: this.state.hostId }} placeholder="Search for host" loadOptions={async (input) => {
                            var options = await StaffClient.SearchStaff(this.state.accessToken, input, await GetSiteId(this.state.accessToken));
                            return options.data.data.map((t) => {
                                return {
                                    label: t.name + " " + t.surname,
                                    value: t.id
                                };
                            });
                        }} onChange={(option) => {
                            this.setState({
                                hostName: option.label,
                                hostId: option.value
                            });
                        }}
                            classNames={{
                                control: () => 'form-input px-4 py-3 mt-4 w-full border-slate-400 border-b-2',
                            }} />

                        <h3 className="text-white mt-4">Favourites</h3>
                        <div className="flex flex-col justify-items-stretch">
                            {this.state.favourites.length == 0 && <p className="font-light  mt-4 p-2 bg-white/50 rounded-sm">People you visit often will show up here.</p>}
                            {this.state.favourites.length > 0 && this.state.favourites.map(f => <div className="bg-white rounded-sm cursor-pointer p-4 mt-4 flex flex-row align-items-center justify-content-start" onClick={() => {
                                this.setState({
                                    hostName: f.name + " " + f.surname,
                                    hostId: f.id
                                });
                            }}>
                                <div className="w-20 h-20 p-2">
                                    <img className="rounded-full w-full h-full" src={f.accountPhoto == null ? "/img/missing-profile.jpg" : this.state.apiDomain + "/image/profile/" + f.accountPhoto.id} alt="" />
                                </div>
                                <div className="p-4">
                                    <p>{f.name} {f.surname}</p>
                                    <p className="text-slate-400">{f.email}</p>
                                </div>
                            </div>)}
                        </div>

                        {this.state.errors.phone && <p className="text-red-700 font-light mt-4 p-2 bg-slate-50 rounded-sm">{this.state.errors.phone}</p>}
                    </form>
                </div>
            </div>);

        } else if (this.state.stage == "notices") {

            return (<div>
                <NavBar title="Health and Safety" loading={this.state.loading} back={async () => {
                    this.setState({
                        stage: "host"
                    });
                }} />

                <div className="container mx-auto">
                    <form id="noticesform" className="max-w-4xl w-full mx-auto px-8 sm:px-0 mt-4">

                        <div className="flex flex-col justify-items-stretch">
                            {this.state.notices.length == 0 && <p className="font-light  mt-4 p-2 bg-white/50 rounded-sm">No current advisory notices to acknowledge.</p>}
                            {this.state.notices.length > 0 && this.state.notices.map(f => <div className="bg-white rounded-sm p-4 mt-4">
                                <div className="p-4">
                                    {f.format == "text" && <p className="text-slate-400 prose lg:prose-xl">{f.content}</p>}
                                    {f.format == "html" && <p className="text-slate-400 prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: f.content }}></p>}
                                    {f.format == "markDown" && <p className="text-slate-400 prose lg:prose-xl"><ReactMarkdown>{f.content}</ReactMarkdown></p>}
                                </div>
                            </div>)}
                        </div>

                        {this.state.errors.phone && <p className="text-red-700 font-light mt-4 p-2 bg-slate-50 rounded-sm">{this.state.errors.phone}</p>}
                    </form>


                    {this.state.errors.validation && <p className="text-red-700 font-light p-2 bg-slate-50 rounded-sm max-w-4xl w-full mx-auto mb-4">{this.state.errors.validation}</p>}


                    <div className="flex flex-row content-center flex-wrap justify-items-center justify-center p-8">
                        <div><a href="#/" onClick={async () => {

                            this.setState({
                                loading: true
                            });

                            var response = await CheckInClient.CheckInVisitor(this.state.accessToken,
                                this.state.hostId,
                                this.state.carParkId,
                                this.state.notices.map(n => n.id),
                                this.state.carRego);

                            var site = await SiteClient.GetSite(this.state.accessToken, await GetSiteId());

                            await CheckInClient.SubmitPrintRequest(this.state.accessToken, response.data.data.id);

                            this.setState({
                                loading: false,
                                printLabel: site.data.data.printLabel,
                                sendLabelByEmail: site.data.data.sendLabelByEmail
                            });

                            if (response.successful) {
                                this.setState({
                                    stage: "confirmation"
                                });
                            } else {
                                if (response.data.error != null && response.data.error.validationErrors != null && response.data.error.validationErrors.length > 0) {
                                    this.setState({ errors: { validation: response.data.error.validationErrors[0].message } });
                                } else {
                                    this.setState({ errors: { validation: "Sorry, we can't complete that request right now. Please ensure data entered is correct and try again." } });
                                }
                            }

                            return false;

                        }} className="flex justify-center bg-blue-500 hover:bg-blue-700 text-white text-center py-2 px-6 rounded-full">
                            <>{this.state.loading && <svg aria-hidden="true" className="w-4 h-4 mr-2 mt-1 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 align-middle" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>}</>I accept
                        </a></div>
                    </div>
                </div>
            </div>);

        } else if (this.state.stage == "confirmation") {


            return (
                <div  className="flex flex-col h-screen">
                    <div className="container mx-auto mt-12 flex-grow">
                        <form id="comfirmation" className="max-w-4xl w-full mx-auto px-8 sm:px-0 mt-4">

                            <h1 className="text-4xl text-white text-center">Thank you, {this.state.user && this.state.user.visitorAccount && this.state.user.visitorAccount.name}</h1>
                            <h1 className="text-6xl text-white font-light text-center mt-2">You're signed in.</h1>


                            {this.state.printLabel && this.state.sendLabelByEmail && <p className="font-light text-white text-center mt-8">Please collect your visitor badge from frontdesk. We have also emailed you a copy of your badge.</p>}
                            {this.state.printLabel && !this.state.sendLabelByEmail && <p className="font-light text-white text-center mt-8">Please collect your visitor badge from frontdesk.</p>}
                            {!this.state.printLabel && this.state.sendLabelByEmail && <p className="font-light text-white text-center mt-8">We have emailed you your visitor badge.</p>}

                            {!this.state.showSuccess && <p className="text-white mt-12 w-full flex items-center text-center">Need WiFi access? We'll send you the details via Email</p>}

                            {!this.state.showSuccess && <a href="#/" onClick={async () => {

                                this.setState({
                                    loading: true
                                });

                                await WiFiClient.SendDetails(this.state.accessToken, this.state.mobile);

                                this.setState({
                                    loading: false,
                                    showSuccess: true
                                });
                            }} className="mt-8 flex justify-center flex-shrink bg-blue-500 hover:bg-blue-700 text-white text-center py-2 px-6 rounded-full ml-2">
                                <>{this.state.loading && <svg aria-hidden="true" className="w-4 h-4 mr-2 mt-1 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 align-middle" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>}</>Get connected
                            </a>}

                            {this.state.showSuccess && <p className="font-light text-white text-center mt-8">WiFi details have been emailed to you</p>}
                        </form>
                    </div>

                    <div className="b-bg m-8">
                        <div className="mx-auto flex justify-center">
                            <div className="b-bg">
                                <img src="/img/logo-thumb.png" style={{ height: "100px" }} />
                            </div>
                            <span className="text-white" style={{ fontSize: "38px", position: "relative", top: "20px", left: "-15px" }}>RRIVALS</span>
                        </div>
                    </div>
                </div>
            );
        } else {

            ClearSiteId();

            this.setState({
                accessToken: null,
                stage: "mobile"
            });

            return (<p className="text-red-700 font-light p-2 bg-slate-50 rounded-sm max-w-4xl w-full mx-auto mb-4">Woops, something went wrong, please reload the application</p>);
        }
    }
}
