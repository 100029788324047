import React, { Component } from 'react';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <section className="w-full h-screen">
            <img
                src="/img/bg/one-texture.jpg"
                className="object-cover w-full h-screen blur-lg bg-local fixed top-0 z-0"
                alt="Background image for web application"
            />
            <div className="z-10 relative">
                {this.props.children}
            </div>
        </section>
    );
  }
}



