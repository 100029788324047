import { ApiResponse } from "../ApiClient";
import { GetAPIDomain } from "../ApiHelper";

export class MobileNumberClient {

    static async BeginValidation(mobile: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/mobile-numbers/begin-validation', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mobileNumber: mobile,
                deviceOS: "web"
            })
        }));

        return response;
    }


    static async CompleteValidation(mobile: string, code: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/mobile-numbers/complete-validation', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mobileNumber: mobile,
                verificationCode: code
            })
        }));

        return response;
    }
}