import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { ApiHelper } from './infrastructure/ApiHelper';
import './output.css';

export default class App extends Component {
  static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: ApiHelper.IsAuthenticated(),
            carParkId: ApiHelper.CarParkId(),
        };

        this.refreshApplicationState = this.refreshApplicationState.bind(this);

    }

    async componentDidMount() {
        await this.refreshApplicationState();
    }

    async refreshApplicationState() {

        this.setState({
            isAuthenticated: ApiHelper.IsAuthenticated(),
            carParkId: ApiHelper.CarParkId(),
        });
    }

  render() {
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Layout>
    );
  }
}
