import { ApiResponse } from "../ApiClient";
import { GetAPIDomain } from "../ApiHelper";

export class VisitorAccountClient {

    static async UpsertVisitorAccount(accessToken: string, email: string, mobile: string, name: string, surname: string, company: string, type: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/visitor-accounts', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            body: JSON.stringify({
                userId: "current",
                email: email,
                mobile: mobile,
                name: name,
                surname: surname,
                company: company,
                type: type,
            })
        }));

        return response;
    }

}