import { ApiResponse } from "../ApiClient";
import { GetAPIDomain } from "../ApiHelper";

export class StaffClient {

    static async GetFavourites(accessToken: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/staff/favourites', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }));

        return response;
    }

    static async SearchStaff(accessToken: string, query: string, siteId: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/staff?q='+ query +'&siteId='+siteId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }));

        return response;
    }

}