import { ApiResponse } from "../ApiClient";
import { GetAPIDomain } from "../ApiHelper";

export class CarParkClient {

    static async GetCarPark(id: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/car-parks/' + id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }));

        return response;
    }

    static async GetCarParks(accessToken: string, q: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/car-parks?q=' + q, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }));

        return response;
    }

}