import { CarParkClient } from "./api/CarParkClient";

export async function GetAPIDomain() {
    var domain = localStorage.getItem("api-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainApi;
        localStorage.setItem("api-domain", domain);
    }

    return domain;
}


export async function ClearSiteId() {
    localStorage.removeItem("site-id3");
}

export async function GetSiteId(accessToken: string) {
    var siteId = localStorage.getItem("site-id3");

    if (!siteId) {

        var carParkId = ApiHelper.GetCurrentCarParkId();

        if (!carParkId)
            return;

        const response = await CarParkClient.GetCarPark(carParkId);

        siteId = response.data.data.site.id;

        localStorage.setItem("site-id3", siteId);
    }

    return siteId;
}

export class ApiHelper {

    
    static ClearCarParkId() {
        localStorage.removeItem("carpark-id");
}


    //Current tenant available
    static CarParkId() {

        return ApiHelper.GetCurrentCarParkId() != null;
    }

    static GetCurrentCarParkId() {

        if (localStorage == null)
            return null;

        if (window.location.hash) {

            var carparkId = window.location.hash.slice(1, window.location.hash.length);
            if (carparkId.length > 5) {
                ClearSiteId();

                var parts = carparkId.split("_");

                localStorage.setItem("carpark-id", parts[0]);
                if (parts.length > 1)
                    localStorage.setItem("carpark-name", parts[1].replace("%20", " ").replace("%20", " "));
            }
            return parts[0];

        } else {
            return localStorage.getItem("carpark-id");
        }

    }

    static GetCurrentCarParkName() {

        if (localStorage == null)
            return null;

        if (window.location.hash) {
            this.GetCurrentCarParkId();
        }

        return localStorage.getItem("carpark-name");
    }

    static IsAuthenticated() {

        if (localStorage == null)
            return false;

        if (localStorage.getItem("access-token") == null)
            return false;

        var token = localStorage.getItem("access-token");
        if (token != null && token.length === 0)
            return false;

        return true;
    }
}