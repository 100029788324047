import { ApiResponse } from "../ApiClient";
import { GetAPIDomain } from "../ApiHelper";

export class NoticesClient {

    static async GetNotices(accessToken: string, siteId: string) {

        var apiDomain = await GetAPIDomain();

        var response = await ApiResponse.CreateFromResponse(await fetch(apiDomain + '/api/notices?siteId='+siteId+'&status=visible', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }));

        return response;
    }

}